import React from 'react'
import { useTransition, animated } from 'react-spring';

export default ({ result }) => {
  if (result && !result.msg) return null

  const transition = useTransition(result, null, {
    config: { duration: 300 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transition.map(({ item, key, props }) =>
    item &&
    <animated.h2 key={key} style={props}>

      {result && result.msg}

    </animated.h2 >)
}