import React from 'react'
import SEO from '../components/SEO/seo'
import { graphql } from 'gatsby'
import SignUpForm from '../components/SignupForm'

export default ({ data }) => {
    const doc = data.prismic.allNewsletters.edges.slice(0, 1).pop()
    if (!doc) return null;

    return (
        <>
            <SEO title='Newsletter' />
            <SignUpForm doc={doc} />
        </>
    )
}

export const query = graphql`
query NewsletterQuery($uid: String, $lang: String) {
  prismic {
    allNewsletters(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            lang
            uid
            type
            alternateLanguages {
                lang
                uid
                type
            }
          }
          button_placeholder
          label_description
          note_text
        }
      }
    }
  }
}
`;