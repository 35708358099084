import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import addToMailchimp from 'gatsby-plugin-mailchimp'
import SignUpMessage from './SignUpMessage'

import { RichText } from 'prismic-reactjs'

const Label = styled.label`
${tw`mb-8 block text-lg`}
`;

const StyledSignUpForm = styled.div`
${tw`flex justify-center items-center flex-col sm:pt-24`}

& input:first-of-type {
  ${tw`mr-8 py-1 rounded-none w-40 
  sm:w-56 text-lg tracking-wider 
  border-b border-gray-500`}
}

& form {
  flex-wrap: nowrap;
}

& label {
  font-family: 'Futura Medium';
}

& h2 {
  ${tw`text-sm mt-10`}
  font-family: 'Futura Medium';
  height: 2rem;
}

& p {
  ${tw`text-xs sm:text-sm sm:mt-3`}
  font-family: 'Futura Light';
}

& button {
  ${tw`sm:px-2 py-1 relative tracking-wide sm:text-sm`}
  height: 35px;
  font-family: 'Futura Medium';
}

 & button:after {
  background: none repeat scroll 0 0 transparent;
  background-color: black;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

& button:hover:after { 
  width: 100%; 
  left: 0; 
}
`;


const SignUpForm = ({ doc }) => {
  const [email, setEmail] = useState('')
  const [result, setResult] = useState(null)

  const handleChange = (e) => {
    setResult(' ')
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const result = await addToMailchimp(email)
      let isSubscribedAlready = 'already subscribed'
      if (result.msg.toString().includes(isSubscribedAlready)) {
        result.msg = 'It seems that you already have subscribed.'
        setResult(result)
      }
      else {
        setResult(result)
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <StyledSignUpForm>
      <form onSubmit={(e) => handleSubmit(e, email)}>
        <Label htmlFor='email'>
          {RichText.asText(doc.node.label_description)}
        </Label>
        <input
          value={email}
          type='email'
          id='email'
          onChange={e => handleChange(e)}
          placeholder='Email*' />
        <button type='submit'>
          {RichText.asText(doc.node.button_placeholder)}
        </button>
        <RichText render={doc.node.note_text} />
      </form>
      <SignUpMessage
        result={result}
        email={email}
      />
    </StyledSignUpForm>
  )
}

export default SignUpForm
